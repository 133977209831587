@import url('https://fonts.googleapis.com/css2?family=Handjet&family=Rubik:wght@400;500&display=swap');

* {
  font-family: 'Handjet', sans-serif;
  font-family: 'Rubik', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #060e1d;
  color: #f8fafc;
}

@layer base {
  :root {
    --radius: 0.5rem;

    --background: #0a101e;
    --foreground: hsl(210 40% 98%);
    --card: hsl(222.2 84% 4.9%);
    --card-foreground: hsl(210 40% 98%);
    --popover: hsl(222.2 84% 4.9%);
    --popover-foreground: hsl(210 40% 98%);
    --primary: #2563eb;
    --primary-foreground: hsl(222.2 47.4% 11.2%);
    --secondary: hsl(217.2 32.6% 17.5%);
    --secondary-foreground: hsl(210 40% 98%);
    --muted: hsl(217.2 32.6% 17.5%);
    --muted-foreground: hsl(215 20.2% 65.1%);
    --accent: hsl(217.2 32.6% 17.5%);
    --accent-foreground: hsl(210 40% 98%);
    --destructive: hsl(0 62.8% 30.6%);
    --destructive-foreground: hsl(210 40% 98%);
    --border: hsl(217.2 32.6% 17.5%);
    --input: hsl(217.2 32.6% 17.5%);
    --ring: hsl(224, 71%, 50%);
  }
}

.grid-new {
  height: fit-content;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='https://www.w3.org/2000/svg' width='100' height='100'%3E%3Crect width='100' height='100' fill='rgb(6, 14, 29)' /%3E%3Crect x='50%' width='1' height='100%' fill='rgb(21, 31, 50)' /%3E%3Crect y='50%' width='100%' height='1' fill='rgb(21, 31, 50)' /%3E%3C/svg%3E%0A");
  background-repeat: repeat;
}

input[type="color"]::-moz-color-swatch {
  border: none;
  padding: 0;
  border-radius: 5px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border: none;
  border-radius: 5px;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  padding: 0;
  border-radius: 5px;
}

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 2px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: #374151; /* Tailwind CSS slate-700 color */
    border-radius: 10px; /* Rounded corners for the scrollbar handle */
}

::-webkit-scrollbar-track {
    background-color: transparent; /* Color of the scrollbar track */
}

/* For Firefox */
html {
    scrollbar-width: thin; /* Makes the scrollbar thin */
    scrollbar-color: #374151 #f1f1f1; /* Color of the scrollbar handle and track */
}

.goog-te-gadget {
    border: 1px solid red !important;
}

.goog-logo-link {
    border: 1px solid blue !important;
}

.goog-te-banner-frame.skiptranslate {
    border: 1px solid green !important;
}

.goog-te-gadget-simple {
    border: 1px solid yellow !important;

}

.header-title {
    font-family: 'Rubik', sans-serif;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }

.animate-shake {
    animation: shake 0.5s;
}
